<template>
    <div class="w-80-l w-90 wrap1">
        <plan-box v-if="state.changeOrder" />
        <pay-order-modal v-else />
        <start-free-trial-modal
            @on-successful-payment="onSuccessfulPayment"
            @on-cancelled-payment="onCancelledPayment"
            @on-skip-payment="onSkipPayment"
        />

        <div class="w-90 center pt5 pb4" style="margin: auto">
            <section class="planSec1">
                <div class="flex justify-end">
                    <router-link to="#" @click.prevent="signOutUser" class="flex items-center pv2 ph3 overflow-hidden menu-hover">
                        <img :src="require('@/assets/images/logout.svg')" style="width: 15px" alt="" />
                        <div class="font-w1 pl2">Logout</div>
                    </router-link>
                </div>
                <div class="tc pb4">
                    <img :src="require('@/assets/images/simplebks-logo.png')" style="width: 180px" alt="simplebks-logo" />
                </div>
                <div class="b font-w3 planHeader pb2">Choose the right plan that fits your business</div>
                <div class="planSub">Find the perfect plan for your business and keep your finances in check</div>
                <!-- <div>{{ userData.org }}</div> -->
                <div class="planBtnCon pt2">
                    <span :class="['planBtn', { planBtnActive: activeBtn === 'nairaBtn' }]" @click="handleActiveBtn('nairaBtn')"
                        >Naira</span
                    >
                    <span :class="['planBtn', { planBtnActive: activeBtn === 'dollarBtn' }]" @click="handleActiveBtn('dollarBtn')"
                        >Dollar</span
                    >
                </div>
            </section>
            <section class="planSec2">
                <div>
                    <span class="b">Monthly</span>
                    <span><Toggle v-model="toggleValue" @click="handlePlanPer()" class="togglePlan" id="toggle" /></span>
                    <span class="b">Yearly</span>
                </div>
            </section>
            <section class="planSec3">
                <div class="planSec3Con">
                    <div>
                        <div class="allHeader basicHeader">Basic</div>
                        <div class="subHeader">Designed for Freelancer, Contractors and Small Businesses.</div>
                        <div class="priceCon">
                            <span class="planPrice">{{ formatAmount(prices[0], state.currency) }}</span>
                            <span class="planPer">/{{ monthYear }}</span>
                        </div>
                        <div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">1 User</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">All benefits of Free</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Send custom invoices & quotes</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Inventory management</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Contact/Customer Management</span>
                            </div>
                            <div class="b readMore">Read more</div>
                        </div>
                    </div>
                    <div>
                        <button
                            v-if="state.paymentPlanType === 4 && !state.isPaid"
                            @click="paySubscription('basic')"
                            class="w-100"
                        >
                            Complete Order
                        </button>
                        <button v-else @click="changePlan('basic')" class="w-100">Subscribe</button>
                        <div class="pv3 tc">
                            <a href="#" @click="startFreeTrial(4)" class="underlineText">Start 14 days free trial</a>
                        </div>
                    </div>
                </div>
                <div class="planSec3Con">
                    <div>
                        <div class="allHeader basicHeader" style="color: #e07221">Store</div>
                        <div class="subHeader">Perfect for store owners with advanced needs.</div>
                        <div class="priceCon">
                            <span class="planPrice">{{ formatAmount(prices[1], state.currency) }}</span
                            ><span class="planPer">/{{ monthYear }}</span>
                        </div>
                        <div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Point of Sale</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Advanced Inventory Management</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Multi-store</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" style="flex" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Multi user (3 Free, $5/N5,000 pm for additional user)</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Accounting: P&L, Sales, Expenses, Inventory Management</span>
                            </div>
                            <div class="b readMore">Read more</div>
                        </div>
                    </div>
                    <div>
                        <button
                            v-if="state.paymentPlanType === 5 && !state.isPaid"
                            @click="paySubscription('store')"
                            class="w-100"
                        >
                            Complete Order
                        </button>
                        <button v-else @click="changePlan('store')" class="w-100">Subscribe</button>
                        <div class="pv3 tc">
                            <a href="#" @click="startFreeTrial(5)" class="underlineText">Start 14 days free trial</a>
                        </div>
                    </div>
                </div>
                <div class="planSec3Con">
                    <div>
                        <div class="allHeader basicHeader" style="color: #24e9ba">Professional</div>
                        <div class="subHeader">Suitable for large enterprises with complex needs and multiple businesses</div>
                        <div class="priceCon">
                            <span class="planPrice">{{ formatAmount(prices[2], state.currency) }}</span
                            ><span class="planPer">/{{ monthYear }}</span>
                        </div>
                        <div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Multi-business</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Multi user (3 Free, $5/N5,000 pm for additional user)</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Production Management</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Paystack Integration</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Paid In-office Launch/Onboarding service</span>
                            </div>
                            <div class="b readMore">Read more</div>
                        </div>
                    </div>
                    <div>
                        <button
                            v-if="state.paymentPlanType === 6 && !state.isPaid"
                            @click="paySubscription('professional')"
                            class="w-100"
                        >
                            Complete Order
                        </button>
                        <button v-else @click="changePlan('professional')" class="w-100">Subscribe</button>
                        <div class="pv2 tc">
                            <a href="#" @click="startFreeTrial(6)" class="underlineText">Start 14 days free trial</a>
                        </div>
                    </div>
                </div>
                <div class="planSec3Con">
                    <div>
                        <div class="allHeader basicHeader" style="color: #f42828">Enterprise</div>
                        <div class="subHeader">Suitable for large enterprises with complex needs and multiple businesses.</div>
                        <div class="priceCon">
                            <span class="planPrice">{{ formatAmount(prices[3], state.currency) }}</span
                            ><span class="planPer planPrice">/{{ monthYear }}</span>
                        </div>
                        <div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">10 Free User ($5/N5,000 pm for additional user)</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Point of Sale</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Advanced Inventory Management</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Purchase Order</span>
                            </div>
                            <div class="flex items-center pv1" style="gap: 5px">
                                <span class="w-5"
                                    ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                                /></span>
                                <span class="w-90">Inventory Transfer</span>
                            </div>
                            <div class="b readMore">Read more</div>
                        </div>
                    </div>
                    <div>
                        <button
                            v-if="state.paymentPlanType === 7 && !state.isPaid"
                            @click="paySubscription('enterprise')"
                            class="w-100"
                        >
                            Complete Order
                        </button>
                        <button v-else @click="changePlan('enterprise')" class="w-100">Subscribe</button>
                        <div class="pv2 tc">
                            <a href="#" @click="startFreeTrial(7)" class="underlineText">Start 14 days free trial</a>
                        </div>
                    </div>
                </div>
            </section>
            <section class="planSec4">
                <div class="freeSec1">
                    <div class="free">Free</div>
                    <div class="pv2">Ideal for new new businesses, online ventures, and side hustles.</div>
                    <div>
                        <button @click="changePlan('free')" class="getStartedBtn">Get Started</button>
                    </div>
                </div>
                <div class="mbFree">
                    <div class="freeSec2">
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"
                                ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                            /></span>
                            <span class="w-90">1 User</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"
                                ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                            /></span>
                            <span class="w-90">Send outstanding reminders and get paid faster</span>
                        </div>
                    </div>
                    <div class="freeSec2">
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"
                                ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                            /></span>
                            <span>Receipt generation</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"
                                ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                            /></span>
                            <span class="w-90">Unlimited Invoicing</span>
                        </div>
                    </div>
                    <div class="freeSec2">
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"
                                ><img :src="require('@/assets/images/Tick.svg')" width="15" height="15" alt=""
                            /></span>
                            <span class="w-90">Track Income & Expenses</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'
import PayOrderModal from '@/views/Auth/OnBoarding/PayOrderModal'
import StartFreeTrialModal from '@/views/Auth/OnBoarding/StartFreeTrialModal'
import PlanBox from '@/views/Settings/widgets/PlanBox'
import { formatAmount, businessPlans, plansCost, defaultUsers, defaultBusinesses } from '@/utils/lib'

export default {
    name: 'CompleteOrder',
    components: { PayOrderModal, StartFreeTrialModal, PlanBox, Toggle },
    setup() {
        const store = useStore()
        const currentPlan = computed(() => store.state?.Settings?.paymentPlan)
        const businesses = computed(() => store?.state?.Settings?.businesses)
        const userData = computed(() => store?.state?.Auth?.userData)

        const activeBtn = ref('nairaBtn')
        const nairaDollar = ref('N')
        const prices = ref([5000, 50000, 50000, 100000])
        const toggleValue = ref(false)
        const monthYear = ref('Monthly')

        const initaialState = {
            paymentPlanType: 0,
            currency: 'NGN',
            period: 'monthly',
            isPaid: false,
            hasExpired: false,
            changeOrder: false,
        }

        const state = reactive(initaialState)

        watch(currentPlan, (value) => {
            if (value) {
                state.isPaid = value.isPaid
                state.hasExpired = value.hasExpired
                if (value.businessPlan === 'Micro Business Plan') {
                    state.paymentPlanType = 1
                } else if (value.businessPlan === 'Small Business Plan') {
                    state.paymentPlanType = 2
                } else if (value.businessPlan === 'Medium Enterprise Plan') {
                    state.paymentPlanType = 3
                } else if (value.businessPlan === 'Basic Plan') {
                    state.paymentPlanType = 4
                }
            }
        })

        const paySubscription = async (plan) => {
            state.changeOrder = false
            let businessPlan = businessPlans[plan]
            await store.dispatch('Settings/setPlan', businessPlan)
            onShowCreatePlan(true)
        }

        const changePlan = async (plan) => {
            state.changeOrder = true
            let hasAdditionalUsers = false
            let hasAdditionalBusiness = false
            let additionalUsers = 0
            let additionalBusiness = 0
            let businessPlan = businessPlans[plan]
            let planData = null
            if (plan === 'free') {
                hasAdditionalUsers = false
                hasAdditionalBusiness = false
                additionalUsers = 0
                additionalBusiness = 0

                planData = {
                    hasAdditionalUsers: hasAdditionalUsers,
                    hasAdditionalBusiness: hasAdditionalBusiness,
                    additionalUsers: additionalUsers,
                    additionalBusiness: additionalBusiness,
                    businessPlan: businessPlan,
                }

                store.dispatch('Settings/createPlan', planData).then((resp) => {
                    if (resp?.success) {
                        Swal.fire({
                            title: 'Success',
                            text: 'Redirecting to Paystack to process your subscription',
                            type: 'success',
                            confirmButtonText: 'OK',
                        })
                        // state.disableButton = false
                    } else {
                        // state.disableButton = false
                    }
                })
            } else {
                await store.dispatch('Settings/setPlan', businessPlan)
                onShowCreatePlan(true)
            }
        }

        const freeTrial = (plan, paystackResponse = {}) => {
            const planData = {
                hasAdditionalUsers: false,
                additionalUsers: 0,
                hasAdditionalBusiness: false,
                additionalBusiness: 0,
                businessPlan: plan,
                users: defaultUsers[plan],
                businesses: defaultBusinesses[plan],
                period: state.period,
            }

            if (paystackResponse?.reference) {
                planData.reference = paystackResponse.reference
            }

            store.dispatch('Settings/createFreeTrial', planData)
        }

        const onShowCreatePlan = (payload = true) => {
            store.dispatch('Settings/showCreatePlan', payload)
        }

        const showFreeTrialModal = (payload = true) => {
            store.dispatch('Settings/showFreeTrialModal', payload)
        }

        const startFreeTrial = async (plan) => {
            await store.dispatch('Settings/setPlanIndex', plan)
            showFreeTrialModal(true)
        }

        const switchBusiness = (event) => {
            store.dispatch('Settings/switchBusiness', event.target.value)
        }

        const handlePrices = () => {
            if (!toggleValue.value) {
                prices.value = activeBtn.value === 'nairaBtn' ? [5000, 50000, 50000, 100000] : [10, 100, 100, 200]
            } else {
                prices.value = activeBtn.value === 'nairaBtn' ? [50000, 500000, 500000, 1000000] : [100, 1000, 1000, 2000]
            }
        }

        const handleActiveBtn = async (button) => {
            activeBtn.value = button
            nairaDollar.value = activeBtn.value === 'nairaBtn' ? 'N' : '$'
            state.currency = activeBtn.value === 'nairaBtn' ? 'NGN' : 'USD'
            handlePrices()
            await store.dispatch('Settings/setPlanCurrency', state.currency)
        }

        const handlePlanPer = async () => {
            monthYear.value = toggleValue.value === false ? 'Monthly' : 'Annum'
            state.period = toggleValue.value === false ? 'monthly' : 'yearly'
            handlePrices()
            await store.dispatch('Settings/setPlanPeriod', state.period)
        }

        const onSuccessfulPayment = async (plan, response) => {
            if (response.status === 'success') {
                store
                    .dispatch('Settings/generateTransactionId')
                    .then((data) => {
                        state.reference = data.reference
                        freeTrial(plan, response)
                    })
                    .finally(showFreeTrialModal(false))
            }
        }

        const onCancelledPayment = () => {
            showFreeTrialModal(false)
        }

        const onSkipPayment = (plan) => {
            showFreeTrialModal(false)
            freeTrial(plan)
        }

        const signOutUser = () => store.dispatch('Auth/signOut')

        onMounted(() => {
            store.dispatch('Settings/getOrganization')
            store.dispatch('Settings/getBusinesses')
            store.dispatch('Settings/getPlan')
        })

        return {
            state,
            userData,
            paySubscription,
            startFreeTrial,
            changePlan,
            onShowCreatePlan,
            showFreeTrialModal,
            currentPlan,
            businesses,
            switchBusiness,
            freeTrial,
            handleActiveBtn,
            activeBtn,
            nairaDollar,
            toggleValue,
            prices,
            monthYear,
            handlePlanPer,
            handlePrices,
            onSuccessfulPayment,
            onCancelledPayment,
            onSkipPayment,
            signOutUser,
            plansCost,
            formatAmount,
        }
    },
}
</script>

<style scoped>
* {
    box-sizing: border-box !important;
}
.pen-title {
    color: #1a2035;
    margin-bottom: 0;
    text-align: center;
    text-shadow: 1px 1px 0 #000;
}

.pen-description {
    color: #1a2035;
    margin-bottom: 3rem;
    text-align: center;
    text-shadow: 1px 1px 0 #000;
}

.pen-description a {
    color: #1a2035;
    text-decoration: underline;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

img {
    height: auto;
    max-width: 100%;
}

.wrap1 {
    max-width: 85.375rem;
    margin: 0 auto;
}

.image-as-background {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.screen-reader-text {
    height: auto;
    width: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
    height: auto;
    width: auto;
    background-color: #000000;
    border-radius: 2px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #1a2035;
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    left: 0.3125rem;
    line-height: normal;
    padding: 0.9375rem 1.4375rem 0.875rem;
    text-decoration: none;
    top: 0.3125rem;
    z-index: 100000;
}

@media screen and (min-width: 64rem) {
    .wrap1 {
        padding: 1.25rem;
        margin-top: 1rem !important;
    }
}

.pricing-section {
    color: #1a2035;
}

.pricing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 1.875rem;
    width: 100%;
}

.pricing-item {
    align-items: stretch;
    display: flex;
    flex: 0 1 20.625rem;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
}

.pricing-feature-list {
    text-align: left;
}

.pricing-action {
    background: none;
    border: none;
    color: inherit;
}

.pricing-action:focus {
    outline: none;
}

.pricing-theme .pricing-item {
    /* background: rgba(255, 255, 255, 0.08); */
    background-color: #fff;
    border: 0.0625rem solid rgba(255, 255, 255, 0.4);
    border-radius: 0.625rem;
    color: #1a2035;
    cursor: default;
    margin-bottom: 1.25rem;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    transition: border-color 0.3s, background 0.3s;
}

@media screen and (min-width: 40rem) {
    .pricing-theme .pricing-item {
        margin: 1.25rem;
    }
}
.pricing-theme .pricing-item:focus {
    border: 1px solid #132c8c;
}
.pricing-theme .pricing-item.active,
.pricing-theme .pricing-item:hover {
    border: 0.0625rem solid white;
    background: whitesmoke;
    border: 1px solid #132c8c;
    box-sizing: border-box !important;
}

.pricing-theme .subscribe-plan {
    border: 0.0625rem solid white;
    background: rgb(216, 214, 214);
    border: 1px solid #132c8c;
    box-sizing: border-box !important;
}

.pricing-theme .pricing-title {
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0.5rem 0;
    padding: 1.5rem;
    position: relative;
}

.pricing-theme .pricing-title:after {
    position: absolute;
    bottom: 0;
    left: 35%;
    height: 0.0625rem;
    width: 30%;
    background: #132c8c;
    content: '';
}

.pricing-theme .pricing-price {
    font-size: 1.8rem;
    font-weight: 400;
    padding: 1.125rem 0 0 0;
    position: relative;
    z-index: 100;
}

.pricing-theme .pricing-currency {
    font-size: 1.625rem;
    vertical-align: super;
}

.pricing-theme .pricing-sentence {
    margin: 0 auto;
    padding: 0.875rem 1.25rem;
    font-size: 1rem;
}

.pricing-theme .pricing-feature-list {
    font-size: 1rem;
    list-style: none;
    margin: 0 1.875rem;
    padding: 1.875rem 0;
}

.pricing-theme .pricing-feature {
    line-height: 1.4;
}

.pricing-theme .pricing-feature:before {
    content: '✓';
    display: inline-block;
    padding: 0 0.875rem 0 0;
    vertical-align: middle;
}

.pricing-theme .pricing-action {
    background: #132c8c;
    border-radius: 0.3125rem;
    color: #fff;
    flex: none;
    font-weight: 700;
    margin: auto 1.25rem 1.25rem;
    padding: 1rem 1.25rem;
    transition: background 0.3s;
}

.pricing-theme .pricing-action:focus,
.pricing-theme .pricing-action:hover {
    background: #ffffff;
    color: #132c8c;
    border: 2px solid #132c8c;
    box-sizing: border-box !important;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
<style>
.togglePlan {
    --toggle-width: 50px;
    --toggle-height: 25px;
    --toggle-bg-on: #febf12;
    --toggle-bg-off: #fff;
    --toggle-border-on: #febf12;
    --toggle-border-off: #c8b6b6;
    --toggle-ring-width: 3px;
    --toggle-ring-color: transparent;
    --toggle-handle-enabled: #febf12;
    --toggle-handle-disabled: #fff;
    --toggle-border: 1px;
}
input[id='toggle']:checked + .toggle .toggle-handle {
    background-color: #fff;
}

.planSec1 {
    /* width: 599px; */
    padding-bottom: 28px;
}
.planHeader {
    font-size: 20px;
}
.planSub {
    color: #525356;
    font-size: 16px;
}
.planBtnCon {
    display: flex;
    gap: 3px;
}
.planBtn {
    border: none;
    padding: 8px 16px;
    border-radius: 12px;
    font-weight: bold;
    background-color: #f5f5f5;
    color: black;
    cursor: pointer;
}
.planBtnActive {
    background-color: #132c8c;
    color: #fff7f7;
}
.planSec2 {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
}
.planSec2 > div {
    display: flex;
    gap: 5px;
    align-items: center;
}
.planSec3 {
    display: flex;
    gap: 20px;
}
.planSec3 > div {
    padding: 36px 16px;
}
.allHeader {
    font-size: 16px;
    font-weight: bold;
}
.basicHeader {
    color: #3853bc;
}
.subHeader {
    color: #616161;
    font-size: 12px;
    padding-top: 5px;
}
.priceCon {
    padding: 15px 0 25px 0;
}
.planPrice {
    font-size: 16px;
    color: #0a0a0a;
    font-weight: bold;
}
.planPer {
    font-size: 12px;
    color: #050505;
    font-weight: bold;
}
.planSec3Con {
    border: 1px solid #f5f5f5;
    background-color: #fbfbfc;
    width: 30%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
}
.readMore {
    font-size: 10px;
    color: #132c8c;
    padding-top: 7px;
}
.planSec4 {
    border: 1px solid #f5f5f5;
    margin-top: 48px;
    padding: 24px 16px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.freeSec1 {
    width: 40%;
}
.free {
    color: #0a0a0a;
    font-size: 16px;
    font-weight: bold;
}
.getStartedBtn {
    color: #5e5a5a !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px !important;
    background-color: white !important;
    width: 202px;
}
.mbFree {
    width: 70%;
    display: flex;
    justify-content: space-between;
}
.freeSec2 {
    width: 33%;
}
@media screen and (max-width: 900px) {
    .planSec3 {
        flex-wrap: wrap;
        gap: 0px;
        justify-content: space-between;
    }
    .planSec3Con {
        width: 48%;
        margin-bottom: 20px;
    }
    .mbFree {
        display: block;
        width: 55% !important;
    }
    .getStartedBtn {
        width: 100%;
    }

    .freeSec1 {
        width: 50%;
    }
    .freeSec2 {
        width: auto;
    }
}
@media screen and (max-width: 506px) {
    .planSec3 {
        flex-wrap: wrap;
        gap: 0px;
        justify-content: space-between;
    }
    .planSec3Con {
        width: 100%;
        margin-bottom: 20px;
    }
    .getStartedBtn {
        width: 100%;
        margin-bottom: 20px;
    }
    .planSec4 {
        flex-wrap: wrap;
        padding: 24px 16px;
        gap: 0;
    }
    .freeSec1 {
        width: 100%;
    }
    .mbFree {
        width: 100% !important;
    }
}
</style>
