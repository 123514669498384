<template>
    <!-- form modal -->
    <div
        tabindex="0"
        @keydown.esc="toggleFreeTrialModal()"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showFreeTrialModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-30-l w-90 pv2 center box-border" style="background: white" ref="modalRef">
            <div class="box-border-bottom f3 pa3 b">{{ plansName[selectedPlanIndex] }} (Free Trial)</div>
            <div class="entity-form" ref="formRef">
                <div class="alert alert-info">
                    <div><strong><font-awesome-icon icon="fa-exclamation-circle" /> Card Authorization:</strong></div>
                    We will charge your account a <span class="b text-danger">{{ formatAmount(selectedPlanCurrency === 'NGN' ? 50 : 0.02, selectedPlanCurrency) }}</span> box for card authorization.
                </div>
                <div class="ph5">
                    <div class="flex items-center justify-between w-100">
                        <button class="btn" @click="onSkipPayment">Skip</button>
                        <paystack
                            buttonClass=""
                            buttonText="Start 14 days free trial"
                            :publicKey="state.paystackPublicKey"
                            :amount="selectedPlanCurrency === 'NGN' ? 50 * 100 : 0.02 * 100"
                            :firstname="userData.fullName"
                            :email="userData.email"
                            :reference="state.reference"
                            :currency="state.currency"
                            label="Card Authorization: 14-day free trial"
                            :onSuccess="(response) => onSuccessfulPayment(selectedPlanIndex, response)"
                            :onCanel="onCancelledPayment"
                        ></paystack>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { plansName, formatAmount } from '@/utils/lib'
import paystack from 'vue3-paystack';

export default {
    name: 'StartFreeTrialModal',
    components: { paystack },

    setup(props, { emit }) {
        const modalRef = ref(null)
        const store = useStore()
        const userData = computed(() => store?.state?.Auth?.userData)
        const showFreeTrialModal = computed(() => store?.state.Settings.showFreeTrialModal)
        const selectedPlanIndex = computed(() => store?.state.Settings.selectedPlanIndex)
        const selectedPlanCurrency = computed(() => store?.state.Settings.selectedPlanCurrency)
        const selectedPlanPeriod = computed(() => store?.state.Settings.selectedPlanPeriod)

        const state = reactive({
            reference: '',
            currency: 'NGN',
            paystackPublicKey: `${process.env.VUE_APP_PAYSTACK_PUBLIC_KEY}`,
        })

        const toggleFreeTrialModal = (payload = false) => {
            store.dispatch('Settings/showFreeTrialModal', payload)
        }

        // close when click outside modal
        const handleOnClickOutsideModal = e => {
            if (modalRef.value && !modalRef.value.contains(e.target)) {
                toggleFreeTrialModal()
            }
        }

        const onSuccessfulPayment = async (plan, response) => {
            emit('onSuccessfulPayment', plan, response)
        }

        const onCancelledPayment = () => {
            emit('onCancelledPayment')
        }

        const onSkipPayment = () => {
            emit('onSkipPayment', selectedPlanIndex.value)
        }

        onMounted(() => {
            store.dispatch('Settings/generateTransactionId').then((data) => {
                state.reference = data.reference
                state.currency = selectedPlanCurrency.value
            })
        })

        return {
            state,
            showFreeTrialModal,
            toggleFreeTrialModal,
            modalRef,
            handleOnClickOutsideModal,
            plansName,
            selectedPlanIndex,
            selectedPlanCurrency,
            selectedPlanPeriod,
            formatAmount,
            userData,
            onSuccessfulPayment,
            onCancelledPayment,
            onSkipPayment,
        }
    }
}
</script>

<style scoped></style>
