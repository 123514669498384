<template>
    <!-- form modal -->
    <div
        tabindex="0"
        @keydown.esc="toggleCreatePlan()"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showCreatePlan && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-30-l w-90 pv2 center box-border" style="background: white" ref="createCategoryRef">
            <div class="box-border-bottom f3 pa3 b">{{ plansName[selectedPlan] }}</div>
            <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                <div v-if="isTransactionDeclined" class="alert alert-warning tc">
                    Your last transaction was declined, please start another transaction
                </div>
                <div class="flex justify-between ph3 pv2">
                    <div class="flex flex-column">
                        <div class="f4"><p>Plan amount</p></div>
                    </div>
                    <div class="flex flex-column">
                        <div class="f3">
                            <span class="f4">
                                {{ 
                                    formatAmount(
                                        periodMultiplier[selectedPlanPeriod] * (selectedPlanCurrency === 'NGN' 
                                            ? plansCost[selectedPlan] 
                                            : plansCostDollar[selectedPlan]), 
                                        selectedPlanCurrency
                                    ) 
                                }}
                            </span>
                            <span class="f6">/{{ selectedPlanPeriod === 'monthly' ? 'month' : 'annum' }}</span>
                        </div>
                    </div>
                </div>
                <!-- No of additional users -->
                <div class="flex justify-between items-center ph3 pv2">
                    <div class="flex flex-column">
                        <div class="f4">
                            <p>No of additional users</p>
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="pt1">
                            <div class="pv2 font-w1"><label for="name"></label></div>
                            <input
                                required
                                type="number"
                                id="additional_users"
                                name="additional_users"
                                placeholder="Enter number of additional user"
                                v-model="state.additionalUsers"
                                min="0"
                            />
                        </div>
                        <div class="f3">
                            <span class="f4"
                                >({{ state.additionalUsers }}){{
                                    formatAmount((selectedPlanCurrency === 'NGN' ? plansAdditionaUserlCost[selectedPlan] : plansAdditionaUserlCostDollar[selectedPlan]) * state.additionalUsers, selectedPlanCurrency)
                                }}</span
                            >
                            <span class="f6">{{ selectedPlanPeriod === 'monthly' ? 'month' : 'annum' }}</span>
                        </div>
                    </div>
                </div>
                <!-- No of additional business coming soon -->
                <div class="flex justify-between items-center  ph3 pv2">
                    <div class="flex flex-column">
                        <div class="f4">
                            <p>No of additional business</p>
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="pt1">
                            <div class="pv2 font-w1"><label for="name"></label></div>
                            <input
                                required
                                type="number"
                                id="additional_users"
                                name="additional_users"
                                placeholder="Enter number of additional business"
                                v-model="state.additionalBusiness"
                                min="0"
                            />
                        </div>
                        <div class="f3">
                            <span class="f4"
                                >({{ state.additionalBusiness }}){{
                                    formatAmount(
                                        (selectedPlanCurrency === 'NGN' 
                                            ? plansAdditionalBusinessCost[selectedPlan] 
                                            : plansAdditionalBusinessCostDollar[selectedPlan]) * state.additionalBusiness, 
                                            selectedPlanCurrency
                                    )
                                }}</span
                            >
                            <span class="f6">/month</span>
                        </div>
                    </div>
                </div>
                <div class="ph5">
                    <!-- Calculated Total Amount -->
                    <div class="pt1">
                        <div>
                            <div class="pv2 font-w1"><label for="name"></label></div>
                            <div class="f4">Total Amount:
                                <span class="f4 b text-danger">{{
                                    formatAmount(
                                        (periodMultiplier[selectedPlanPeriod] * selectedPlanCurrency === 'NGN' 
                                            ? plansCost[selectedPlan] 
                                            : plansCostDollar[selectedPlan]) + 
                                        ((selectedPlanCurrency === 'NGN' ? plansAdditionaUserlCost[selectedPlan] : plansAdditionaUserlCostDollar[selectedPlan]) * state.additionalUsers) +
                                        ((selectedPlanCurrency === 'NGN' ? plansAdditionalBusinessCost[selectedPlan] : plansAdditionalBusinessCostDollar[selectedPlan]) * state.additionalBusiness), 
                                        selectedPlanCurrency
                                    )
                                }}</span>
                            </div>
                        </div>
                        <div>
                            <div>Total Users:
                                <span class="f4 b text-danger">{{ defaultUsers[selectedPlan] + state.additionalUsers + (2 * state.additionalBusiness) }}</span>
                            </div>
                        </div>
                        <div>
                            <div>Total Businesses:
                                <span class="f4 b text-danger">{{ defaultBusinesses[selectedPlan] + state.additionalBusiness }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-center w-100">
                        <button class="mt4" type="submit" :disabled="state.disableSubmitButton">
                            {{ state.disableSubmitButton ? 'Processing...' : 'Make Payment' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import {
    businessPlans,
    plansName,
    plansCost,
    plansCostDollar,
    periodMultiplier,
    defaultUsers,
    defaultBusinesses,
    plansAdditionaUserlCost,
    plansAdditionaUserlCostDollar,
    plansAdditionalBusinessCost,
    plansAdditionalBusinessCostDollar,
    formatAmount
} from '@/utils/lib'

export default {
    name: 'PlanBox',

    setup(props) {
        const createCategoryRef = ref(null)
        const store = useStore()
        const showCreatePlan = computed(() => store?.state.Settings.showCreatePlan)
        const selectedPlan = computed(() => store?.state.Settings.selectedPlan)
        const selectedPlanPeriod = computed(() => store?.state.Settings.selectedPlanPeriod)
        const selectedPlanCurrency = computed(() => store?.state.Settings.selectedPlanCurrency)
        const isTransactionDeclined = computed(() => store?.state.Settings.isTransactionDeclined)

        const state = reactive({
            additionalUsers: 0,
            additionalBusiness: 0,
            disableSubmitButton: false,
            name: ''
        })

        const toggleCreatePlan = (payload = false) => {
            store.dispatch('Settings/showCreatePlan', payload)
        }

        watch(
            () => state.additionalUsers,
            (curr, prev) => {
                if (curr !== prev) { 
                    if (curr === '') {
                        state.additionalUsers = 0
                    } else {
                        state.additionalUsers = parseInt(curr)
                    }
                }
            }
        )

        watch(
            () => state.additionalBusiness,
            (curr, prev) => {
                if (curr !== prev) { 
                    if (curr === '') {
                        state.additionalBusiness = 0
                    } else {
                        state.additionalBusiness = parseInt(curr)
                    }
                }
            }
        )

        const onSubmit = () => {
            state.disableSubmitButton = true

            const planData = {
                hasAdditionalUsers: state.additionalUsers > 0,
                additionalUsers: state.additionalUsers,
                hasAdditionalBusiness: state.additionalBusiness > 0,
                additionalBusiness: state.additionalBusiness,
                businessPlan: selectedPlan.value,
                users: defaultUsers[selectedPlan.value] + state.additionalUsers + (2 * state.additionalBusiness),
                businesses: defaultBusinesses[selectedPlan.value] + state.additionalBusiness
            }

            store.dispatch('Settings/createPlan', planData).then(() => {
                toggleCreatePlan(false)
                state.disableSubmitButton = false
            })
        }

        // close when click outside modal

        const handleOnClickOutsideModal = e => {
            if (createCategoryRef.value && !createCategoryRef.value.contains(e.target)) {
                toggleCreatePlan()
            }
        }

        const onShowCreatePlan = (payload = true) => {
            store.dispatch('Settings/showCreatePlan', payload)
        }

        onMounted(() => store.dispatch('Inventory/getAllCategories'))

        return {
            state,
            showCreatePlan,
            toggleCreatePlan,
            onSubmit,
            createCategoryRef,
            handleOnClickOutsideModal,
            onShowCreatePlan,
            businessPlans,
            plansName,
            plansCost,
            plansCostDollar,
            periodMultiplier,
            defaultUsers,
            defaultBusinesses,
            plansAdditionaUserlCost,
            plansAdditionaUserlCostDollar,
            plansAdditionalBusinessCost,
            plansAdditionalBusinessCostDollar,
            selectedPlan,
            selectedPlanPeriod,
            selectedPlanCurrency,
            isTransactionDeclined,
            formatAmount,
        }
    }
}
</script>

<style scoped></style>
